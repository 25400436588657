<template>
  <q-form @submit="submitPasswordReset" @reset="$router.push({name: 'login'})">
    <q-banner class="text-center bg-blue-1 q-ma-md" rounded>Please complete the form to reset your password</q-banner>
    <q-card-section class="q-py-none">
      <q-input autofocus placeholder="Password" v-model="creds.password1" type="password" lazy-rules :rules="formRules.password" />
      <q-input placeholder="Confirm Password" v-model="creds.password2" type="password" lazy-rules :rules="formRules.passwordConfirmation" />
    </q-card-section>

    <q-card-actions>
      <q-btn :loading="saving" type="submit" class="full-width" color="blue">Update Password</q-btn>
      <q-btn type="reset" class="full-width q-mt-md" flat>Back to Login</q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
export default {
  name: 'ResetPasswordComponent',
  data () {
    return {
      errorMsg: '',
      saving: false,
      token: null,
      creds: {
        password1: '',
        password2: ''
      }
    }
  },
  computed: {
    formRules () {
      return {
        password: [ this.$formRules.required('Password') ],
        passwordConfirmation: [ this.$formRules.confirmPassword(this.creds.password1) ]
      }
    }
  },
  methods: {
    submitPasswordReset: function (e) {
      this.saving = true
      this.errorMsg = ''

      this.$store.dispatch('auth/resetPasswordUpdate', { token: this.token, password: this.creds.password1, password_confirmation: this.creds.password2 }).then((resp) => {
        this.$router.push({ name: 'login', query: { reset: 3 } })
      }).catch((error) => {
        if (error.status === 422) {
          this.errorMsg = 'Invalid reset token, you may need to click back and click forgot password again.'
        } else {
          this.errorMsg = 'Server could not complete the request at this time.'
        }
        this.$failureMsg(this.errorMsg)
      })
    }
  },
  created () {
    this.token = this.$route.params.token
  }
}
</script>
