<template>
  <div>
    <q-card-section class="row justify-center">
      <q-icon name="warning" class="text-primary q-mb-md" size="75px" />
      <q-banner rounded class="text-center bg-red-1">
        <div class="text-subtitle1">
          We are currently investigating a previous issue with your account. Login is not available at this time. Please try again later.
        </div>
      </q-banner>
      <div class="col-12 row justify-center q-mt-xl">
        <q-btn flat @click="$router.go(-1)">Back To Login</q-btn>
      </div>
    </q-card-section>
  </div>
</template>

<script>
export default {
  name: 'ReconnaissanceComponent'
}
</script>
