<template>
  <div>
    <q-form @submit="login">
      <q-card-section class="q-py-none">
        <q-banner v-if="showInfoMsg"  class="text-center bg-blue-1 q-ma-md" rounded>{{ infoMsg }}</q-banner>
        <q-input autofocus placeholder="Email" v-model="creds.username" type="email" :rules="formRules.email" />
        <q-input placeholder="Password" v-model="creds.password" type="password" :rules="formRules.password" />
      </q-card-section>

      <q-card-actions>
        <q-btn :loading="loggingIn" type="submit" class="full-width" color="blue">LOG IN</q-btn>
      </q-card-actions>

      <q-card-actions class="justify-between q-mt-lg items-end">
        <div> <!-- this div ensures the 'Forgot Password' Button is always on the right side -->
          <q-btn v-if="creds.appVersion" flat size="md" :to="{name: 'version'}" class="text-caption text-grey-7">V {{ creds.appVersion }}</q-btn>
        </div>
        <q-btn :to="{name: 'login', query: {forgot: 1}}" flat size="md">Forgot Password?</q-btn>
      </q-card-actions>
    </q-form>

    <q-dialog v-model="versionIsOutdated" persistent>
      <q-card class="q-pa-lg">
        <q-card-section class="row justify-center">
          <q-icon size="48px" name="update" />
        </q-card-section>
        <q-card-section class="row justify-center">
          <div class="text-h5">Outdated Version</div>
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle1">
            Sorry, it looks like you are using an outdated version of LMS. Please follow the steps below to get the latest version:
          </div>
          <q-list>
            <q-item class="q-my-md">
              <q-item-section avatar>
                <q-avatar size="36px" color="primary" text-color="white">1</q-avatar>
              </q-item-section>
              <q-item-section>
                <div class="text-subtitle1">
                  Close <span class="text-bold">any and all</span> tabs that you may have LMS opened on
                </div>
              </q-item-section>
            </q-item>
            <q-item class="q-my-md">
              <q-item-section avatar>
                <q-avatar size="36px" color="primary" text-color="white">2</q-avatar>
              </q-item-section>
              <q-item-section>
                <div class="text-subtitle1">
                  Close this current tab
                </div>
              </q-item-section>
            </q-item>
            <q-item class="q-my-md">
              <q-item-section avatar>
                <q-avatar size="36px" color="primary" text-color="white">3</q-avatar>
              </q-item-section>
              <q-item-section>
                <div class="text-subtitle1">
                  Reopen the tab or browser and navigate back to LMS
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import versionHistory from '../../statics/versionHistory.json'
export default {
  name: 'LoginComponent',
  data () {
    return {
      versionIsOutdated: false,
      showLoadingDialog: false,
      showInfoMsg: false,
      infoMsg: '',
      loggingIn: false,
      creds: {
        username: null,
        password: null,
        grant_type: 'password',
        client_id: this.$consts.CLIENT_ID,
        client_secret: this.$consts.CLIENT_SECRET,
        appVersion: null
      }
    }
  },
  computed: {
    formRules () {
      return {
        email: [ this.$formRules.required('Email') ],
        password: [ this.$formRules.required('Password') ]
      }
    }
  },
  methods: {
    login: function (e) {
      if (this.$newVersionExists) {
        this.versionIsOutdated = true
        return
      }

      this.$store.dispatch('auth/loggingIn', true)
      this.showLoadingDialog = true
      this.loggingIn = true
      this.showInfoMsg = false
      this.infoMsg = ''

      this.$store.dispatch('auth/login', this.creds).then(response => {
        if (response && response.message === 'recon') {
          this.$router.push({ name: 'reconnaissance' })
          this.$store.dispatch('auth/loggingIn', false)
        } else {
          this.$store.dispatch('auth/getUser').then(resp => {
            this.$logUser(this.$consts.USER_LOGGED_IN, 'Logged In', resp.uid, false)

            if (this.$newVersionExists) {
              this.versionIsOutdated = true
              this.$store.dispatch('auth/loggingIn', false)
              return
            }

            this.$router.push({
              name: 'homePage',
              query: { initLoad: 1 }
            })

            this.$store.dispatch('auth/loggingIn', false)
          })
        }
      }).catch((error) => {
        this.$store.dispatch('auth/loggingIn', false)
        let errorMessage
        switch (error.response.data.code) {
          case 400:
          case 401:
            errorMessage = 'Email or password incorrect'
            break
          default:
            errorMessage = 'Something went wrong during login. Please try again.'
            break
        }
        this.loggingIn = false
        this.$failureMsg(errorMessage)
      })
    }
  },
  async created () {
    if (this.$route.query.reset && _.toInteger(this.$route.query.reset) === 1) {
      this.infoMsg = 'Please check your email for password reset link'
      this.showInfoMsg = true
    } else if (this.$route.query.reset && _.toInteger(this.$route.query.reset) === 3) {
      this.infoMsg = 'Password was successfully reset. Please login with your new password'
      this.showInfoMsg = true
    } else if (this.$route.query.p && this.$route.query.p === '1') {
      this.infoMsg = 'You do not have permission to view that page'
      this.showInfoMsg = true
    }

    this.creds.appVersion = versionHistory[0].versionNumber
  }
}
</script>
