<template>
  <div class="login-main">

    <div class="row full-height justify-center items-center">
      <div class="bg-white" style="max-width: 450px">
        <q-card>
          <q-card-section class="q-py-none">
            <img src="/statics/login/lmslogo.png" class="q-pa-md" style="width: 100%">
          </q-card-section>
          <Login v-if="mode === 'login'" />
          <ForgotPassword v-if="mode === 'forgot'" />
          <ResetPassword v-if="mode === 'reset'" />
          <Reconnaissance v-if="mode === 'reconnaissance'" />
        </q-card>
      </div>
    </div>

  </div>
</template>

<script>
import Login from 'components/auth/login'
import ForgotPassword from 'components/auth/forgotPassword'
import ResetPassword from 'components/auth/resetPassword'
import Reconnaissance from 'components/auth/reconnaissance'

export default {
  name: 'LoginPage',
  components: { Login, ForgotPassword, ResetPassword, Reconnaissance },
  data () {
    return {
      mode: 'login'
    }
  },
  methods: {
    determineMode () {
      if (this.$route.query.forgot && _.toInteger(this.$route.query.forgot) === 1) {
        this.$log(this.$options.name, 'Showing Forgot Password', this.$route.query)
        this.mode = 'forgot'
      } else if (this.$route.name === 'resetPassword') {
        this.mode = 'reset'
      } else if (this.$route.name === 'reconnaissance') {
        this.mode = 'reconnaissance'
      } else {
        this.$log(this.$options.name, 'Showing Standard Login', this.$route.query)
        this.mode = 'login'
      }
    }
  },
  created () {
    this.determineMode()
  },
  watch: {
    '$route' (to, from) {
      this.determineMode()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import url('https://rsms.me/inter/inter.css');
html *
  font-family 'Inter', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-smoothing antialiased
  color black

.login-main
  background #FFFFFF url(/statics/login/lmsbackground.jpg) no-repeat center center
  background-size cover
  height 100vh
</style>
