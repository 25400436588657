<template>
  <div>
    <q-card-section v-if="showSuccess">
      <q-banner>We have sent you an email with instructions to reset to your password</q-banner>
      <div class="col-12 row justify-center q-mt-md">
        <q-btn color="blue" @click="$router.go(-1)">Back To Login</q-btn>
      </div>
    </q-card-section>
    <q-form v-else @submit="submitForgotBtn" @reset="$router.go(-1)">
      <q-card-section>
        <q-input placeholder="Email" v-model="email" type="email" :rules="formRules.email" />
      </q-card-section>
      <q-card-actions>
        <div class="q-pb-md row full-width">
          <div class="col-12 row justify-center">
            <q-btn type="submit" color="blue" class="full-width" :loading="sendingEmail">Reset Password</q-btn>
          </div>
          <div class="col-12 row justify-center q-mt-md">
            <q-btn type="reset" flat>Cancel</q-btn>
          </div>
        </div>
      </q-card-actions>
    </q-form>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordComponent',
  data () {
    return {
      showError: false,
      showSuccess: false,
      errorMsg: '',
      sendingEmail: false,
      email: ''
    }
  },
  computed: {
    formRules () {
      return {
        email: [ this.$formRules.required('Email') ]
      }
    }
  },
  methods: {
    submitForgotBtn: function (e) {
      this.sendingEmail = true
      this.showError = false
      this.errorMsg = ''

      this.$log(this.$options.name, 'submitForgotBtn trying to send reset')
      this.$store.dispatch('auth/resetPassword', { 'email': this.email }).then(response => {
        this.$log(this.$options.name, 'submitForgotBtn', response)
        this.showSuccess = true
      }).catch((error) => {
        this.$log(this.$options.name, 'submitForgotBtn email error', error)

        if (error.status === 500) {
          this.errorMsg = 'Server could not complete the request at this time.'
        } else {
          this.errorMsg = 'Invalid Email'
        }

        this.showError = true
        this.sendingEmail = false
      })
    }
  }
}
</script>
